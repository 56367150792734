module.exports = {
  environment: 'production',
  debug: {
    stacktrace: false,
    firestore: false,
  },
  affirm: {
    public_api_key: '0TLBOMHBIFHYKHWP',
    script: 'https://cdn1.affirm.com/js/v2/affirm.js',
  },
  algolia: {
    search_key: '0eab6318c3b227698c008039551e74f5',
    app_id: 'GUCCXTBDVF',
    index_prefix: 'prod_',
    platform: {
      cms: 'guesthouse-cms',
      store: 'guesthouse-store',
    },
  },
  google_recaptcha: {
    site_key: '6LfU-9QZAAAAAPsKZ6rt312dIvJxv3KP1U5qBKuX',
  },
  google_optimize: {
    store_id: 'OPT-KJMG965',
  },
  google_apis: {
    browser_key: 'AIzaSyCVdyH7dlpZ0ukaLLkI3sE-aj2iC4R0mr0',
  },
  firebase: {
    cms: {
      apiKey: 'AIzaSyCVdyH7dlpZ0ukaLLkI3sE-aj2iC4R0mr0',
      authDomain: 'app.guesthouseshop.com',
      databaseURL: 'https://guesthouse-cms.firebaseio.com',
      projectId: 'guesthouse-cms',
      storageBucket: 'guesthouse-cms.appspot.com',
      messagingSenderId: '201696010676',
      appId: '1:201696010676:web:4491ddc5feb555591392e1',
      measurementId: 'G-NVDTPTSLT5'
    },
    store: {
      apiKey: 'AIzaSyCVdyH7dlpZ0ukaLLkI3sE-aj2iC4R0mr0',
      authDomain: 'guesthouse-cms.firebaseapp.com',
      databaseURL: 'https://guesthouse-cms.firebaseio.com',
      projectId: 'guesthouse-cms',
      storageBucket: 'guesthouse-cms.appspot.com',
      messagingSenderId: '201696010676',
      appId: '1:201696010676:web:4a5ae3eb05f622df1392e1',
      measurementId: 'G-ESBQ87WDXY'
    }
  },
  firestore: {
    settings: {
      ignoreUndefinedProperties: true,
    }
  },
  hosts: {
    cms: 'app.guesthouseshop.com',
    store: 'guesthouseshop.com',
  },
  sentry: {
    cms: {
      environment: 'production',
      dsn: 'https://79808b9a04cf4b70af78123292e0aab2@o518967.ingest.sentry.io/1812906'
    },
    store: {
      environment: 'production',
      dsn: 'https://0d81f73136a046088cb9e283d1f43cd3@o518967.ingest.sentry.io/5628712'
    }
  },
  stream: {
    api_key: '64r3rkswy6df',
  },
  stripe: {
    publishable_key: 'pk_live_dpAQjzKASIGcbKwzSemzvXta',
    connect_client_id: 'ca_ILJKDHcGZGyiADUX57vVBnYfli2gvLKu',
  },
  squarespace: {
    baseUrl: 'https://alex-ryden-wa5f.squarespace.com',
  },
  mailchimp: {
    enabled: true
  },
  segment: {
    writeKey: 'LSOtJAGYFpe8PcnR7gCTjjWyzQnx2d9x',
    dntCookie: 'ghdnt',
  },
  flagOverrides: {
    shoppable_homes: true,
  }
};
