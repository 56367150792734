import CONFIG from '@config';
import * as Sentry from '@sentry/react';

const fixSentryRelease = (ref) => {
  try {
    return ref.split('/').pop();
  } catch (e) {
    return ref;
  }
};

Sentry.init({ 
  dsn : CONFIG.sentry?.cms?.dsn, 
  environment : CONFIG.sentry?.cms?.environment || 'unknown',
  release: fixSentryRelease(GITHUB_REF) || GITHUB_SHA,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 0.2,
  beforeSend: (event, hint) => {
    console.error(hint.originalException);
    if (SENTRY_ENABLED) {
      return event;
    } else {
      console.error(hint.originalException || hint.syntheticException);
      return null;
    }
  }
});

export default Sentry;
